@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Crete+Round:ital@1&family=Martel:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
  --background-color: #202020;
  --primary-color: #ab3783;
  --secondary-color: #c4c241;
  --light-text: #dddddd;
  --dark-text: #333333;
  --off-white: #f0efe4;
  --off-white-tp: #f0efe4f9;
  --light-grey: #dddddd;

  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  color: var(--light-text);
}

h1 {
  font-family: 'Crete Round', serif;
}

h2 {
  font-family: 'Arimo', sans-serif;
}

p {
  font-family: 'Martel', serif;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  display: none;
}
